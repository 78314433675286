import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout/layout'
import ScrollProgress from '../components/ScrollProgressBar'
import ArticleResource from '../components/ArticleResource'
import RelatedResources from '../components/RelatedResources'
import chevronIcon from '../assets/chevron-green.svg'
import { colors, mq } from '../styles/theme'
import { Link, graphql } from 'gatsby'

const ResourceDetailPage = ({ pageContext, data }) => {

  return (
    <Layout>
      <ScrollProgress />
      <ResourceDetailContainer>
        <ResourceLinkContainer>
          <ResourceLinkIcon src={chevronIcon}/>
          <ResourceBackLink to='/resources'>Back to Resources</ResourceBackLink>
        </ResourceLinkContainer>
        <ResourceDetailWrapper>
          <ArticleResource nodeId={pageContext.NodeId} {...pageContext.ResourceContent}/>
        </ResourceDetailWrapper>
        <RelatedResources {...data.paragraphRelatedResources} {...data.allNodeResource}/>
      </ResourceDetailContainer>
    </Layout>
  )
}


export const query = graphql`
  query($ResourceTopic: Int!, $RelatedResourcesId: String!){
    ...RelatedResources
    ...DynamicResources
  }
`

export default ResourceDetailPage

export { Head } from '../components/_GatsbyPageHead'

const ResourceDetailContainer = styled.div`
  max-inline-size: 1440px;
  margin: auto;
`

const ResourceDetailWrapper = styled.section`
  padding-block-start: 20px;
`

const ResourceLinkContainer = styled.span`
  align-items: center;
  display: flex;
  margin-block-start: 60px;
  margin-inline: 20px;
  max-inline-size: 840px;

  ${mq.tablet} {
    margin-inline: auto;
  }
`

const ResourceBackLink = styled(Link)`
  color: ${colors.primary};
  margin-inline-start: -7px;
  text-decoration: none;

  &:after {
    background: ${colors.primary};
    content: '';
    display: block;
    height: 1px;
    transition: width .3s;
    width: 0;
  }

  &:hover::after{
    width: 100%;
  }
`

const ResourceLinkIcon = styled.img`
  margin-inline-end: 12px;
  transform: rotate(90deg);
  width: 18px;
`
