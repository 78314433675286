import React, { useEffect } from 'react'
import { useLocation } from '@reach/router';
import styled, { css } from 'styled-components'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from 'react-share'
import bookmarkResourceIcon from '../../assets/bookmark-resource.svg'
import { isLoggedIn } from '../../services/auth'
import { requestFavoriteResource } from '../../services/resources'
import { navigate } from 'gatsby'

const SocialShare = ({ socialList, nodeId }) => {

  useEffect(() => {
    requestFavoriteResource(nodeId, 'get')
  }, [])

  if (typeof window === 'undefined') {
    return false;
  }

  const url = window.location.href

  const socialElement = (key, icon, index) => {
    switch (key) {
    case 'facebook':
      return (
        <FacebookShare url={url} key={index}>
          <SocialIcon src={icon} alt='' />
        </FacebookShare>
      )
    case 'linkedIn':
      return (
        <LinkedInShare url={url} key={index}>
          <SocialIcon src={icon} alt='' />
        </LinkedInShare>
      )
    case 'twitter':
      return (
        <TwitterShare url={url} key={index}>
          <SocialIcon src={icon} alt='' />
        </TwitterShare>
      )
    case 'email':
      return (
        <EmailShare url={url} key={index}>
          <SocialIcon src={icon} alt='' />
        </EmailShare>
      )
    default:
      break;
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation().pathname.replace(/^\/+/g, '');

  const saveToFavorites = () => {
    if (!isLoggedIn()){
      navigate(`/app/login?from=${location}`)
    } else {
      requestFavoriteResource(nodeId, 'post')
    }
  }

  return (
    <SocialShareWrapper>
      <SocialShareContent>
        <SocialLabel>Share or save</SocialLabel>
        {
          socialList && socialList.map((el, i) => {
            const icon = el.relationships?.field_social_media_image?.relationships?.field_media_image?.localFile?.publicURL
            return (
              socialElement(el.field_key, icon, i)
            )
          })
        }
        <BookmarkBtn onClick={() => saveToFavorites()}>
          <SocialIcon src={bookmarkResourceIcon} alt='' />
        </BookmarkBtn>
      </SocialShareContent>
    </SocialShareWrapper>
  )
}

export default SocialShare

const SocialShareWrapper = styled.div`
  margin-block: 60px;
  position: relative;

  &::after, &::before{
    content: '';
    position: absolute;
    width: 100%;
  }

  &::after{
    bottom: -20px;
    border-bottom: 1px solid #d2d7da;
  }

  &::before{
    top: -20px;
    border-top: 1px solid #d2d7da;
  }
`

const SocialShareContent = styled.div`
  align-items: center;
  column-gap: 20px;
  display: flex;
`

const socialButton = css`
  display: flex;
`

const FacebookShare = styled(FacebookShareButton)`
  ${socialButton}
`

const LinkedInShare = styled(LinkedinShareButton)`
  ${socialButton}
`

const TwitterShare = styled(TwitterShareButton)`
  ${socialButton}
`

const EmailShare = styled(EmailShareButton)`
  ${socialButton}
`

const SocialLabel = styled.span`
  font-family: "BuckeyeSans";
  font-weight: 700;
`

const SocialIcon = styled.img``

const BookmarkBtn = styled.button`
  border: none;
  padding: 0;
  height: 24px;
  width: 24px;
  cursor: pointer;
`
