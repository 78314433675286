import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router';
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { colors, mq } from '../../styles/theme'
import playButton from '../../assets/play-button.svg'
import bookmarkIcon from '../../assets/bookmark.svg'
import bookmarkSelectedIcon from '../../assets/bookmark-selected.svg'
import Article from '../Rtf/Article'
import ReactPlayer from 'react-player'
import SocialShare from '../Social'
import Button from '../Button'
import { isLoggedIn } from '../../services/auth'
import { requestFavoriteResource } from '../../services/resources'
import { useResourceTooltip } from '../../utils/queries/resourceTooltip'

const ArticleResource = ({ field_resource, nodeId }) => {

  const tooltipText = {
    heading: useResourceTooltip()[0]?.field_heading_plain_text,
    copy: useResourceTooltip()[0]?.field_tooltip_description
  }
  const {
    field_prgph_heading_plain_text
  } = field_resource

  const field_social_share = field_resource?.relationships?.field_social_share
  const field_main_content = field_resource?.field_main_content?.processed
  const field_content_video = field_resource?.relationships?.field_content_video
  const field_content_pdf = field_resource?.relationships?.field_content_pdf?.relationships?.field_media_file?.localFile?.publicURL

  const videoParams = {
    url: field_content_video?.field_media_oembed_video,
    caption: field_content_video?.field_video_caption
  }

  const location = useLocation().pathname.replace(/^\/+/g, '');

  const [bookmarked, setBookmarked] = useState(false)

  useEffect(() => {
    requestFavoriteResource(nodeId, 'get').then(res => setBookmarked(res?.status || false))
  }, [])

  const saveToFavorites = () => {
    if (!isLoggedIn()){
      navigate(`/app/login?from=${location}`)
    } else {
      requestFavoriteResource(nodeId, 'post').then(res => setBookmarked(res?.status || false))
    }
  }

  return (
    <ArticleContainer>
      <ArticleHeading>
        {field_prgph_heading_plain_text}
        <ArticleBookmark aria-label="bookmark" onClick={() => saveToFavorites()}>
          <ArticleBookmarkIcon src={bookmarked ? bookmarkSelectedIcon : bookmarkIcon} />
          <ArticleBookmarkTooltip>
            <ArticleBookmarkHeading>{tooltipText.heading}</ArticleBookmarkHeading>
            <ArticleBookmarkCopy>
              {tooltipText.copy}
            </ArticleBookmarkCopy>
          </ArticleBookmarkTooltip>
        </ArticleBookmark>
      </ArticleHeading>
      {
        videoParams.url &&
        <>
          <ArticlePlayerWrapper>
            <ArticlePlayer
              controls
              playing={true}
              muted={true}
              url={videoParams.url}
              light={true}
              width="100%"
              height="100%"
              playIcon={<ArticlePlayerIcon src={playButton} />}
            />
          </ArticlePlayerWrapper>
          <ArticlePlayerCaption>
            {videoParams.caption}
          </ArticlePlayerCaption>
        </>
      }
      <Article copy={field_main_content} />
      {
        field_content_pdf &&
          <ArticleButton>
            <Button text="Download PDF" link={field_content_pdf} target='_blank'/>
          </ArticleButton>
      }
      <SocialShare socialList={field_social_share} nodeId={nodeId} />
    </ArticleContainer>
  );
}

export default ArticleResource

const ArticleContainer = styled.article`
  margin-inline: 20px;
  max-inline-size: 840px;
  display: grid;
  grid-template-rows: auto auto auto auto auto;

  ${mq.tablet} {
    margin-inline: auto;
  }
`;

const ArticleHeading = styled.h1`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-block-end: 40px;

  ${mq.tablet} {
    align-items: center;
  }
`

const ArticleButton = styled.div`
  margin-block-start: 30px;
`

const ArticlePlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  z-index: 1;
`

const ArticlePlayer = styled(ReactPlayer)`
  left: 0;
  position: absolute;
  top: 0;
`

const ArticlePlayerCaption = styled.span`
  color: ${colors.darkGray};
  font-size: 12px;
  letter-spacing: normal;
  padding-top: 7px;
`

const ArticlePlayerIcon = styled.img`
  width: 35px;

  ${mq.tablet} {
    width: 100px;
  }
`

const ArticleBookmarkTooltip = styled.span`
  background: linear-gradient(to right, #4ba492, #26686d);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  display: none;
  left: 45px;
  padding: 5px 0;
  padding-block: 20px;
  position: absolute;
  top: -47px;
  visibility: hidden;
  width: 250px;
  z-index: 1;
  text-align: left;
  padding-inline: 30px;

  &::after {
    border-color: transparent #4ba492 transparent transparent;
    border-style: solid;
    border-width: 10px;
    content: "";
    margin-top: -5px;
    position: absolute;
    right: 100%;
    top: 50%;
  }

  ${mq.large} {
    display: block;
  }
`;

const ArticleBookmarkHeading = styled.span`
  color: ${colors.white};
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 13px;
`

const ArticleBookmarkCopy = styled.p`
  color: ${colors.white};
  font-size: 13px;
`

const ArticleBookmark = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  text-decoration: none;
  
  ${mq.desktop} {
    &:hover{
      ${ArticleBookmarkTooltip} {
        visibility: visible;
      }
    }
  }
`

const ArticleBookmarkIcon = styled.img`
  width: 30px;

  ${mq.tablet} {
    width: 25px;
  }
`
