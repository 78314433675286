import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const ScrollProgress = () => {
  const [width, setWidth] = useState(0)
  const [fixed, setFixed] = useState('relative')

  useEffect(() => {
    window.addEventListener('scroll', scrollHeight);
    return () => window.removeEventListener('scroll', scrollHeight);
  });

  const scrollHeight = () => {
    const el = document.documentElement;
    const ScrollTop = el.scrollTop || document.body.scrollTop;
    const ScrollHeight = el.scrollHeight || document.body.scrollHeight;
    const percent = (ScrollTop / (ScrollHeight - el.clientHeight)) * 100;
    setWidth(percent);
    if (ScrollTop >= 100){
      setFixed('fixed')
    } else {
      setFixed('relative')
    }
  };

  return (
    <ScrollProgressContainer fixed={fixed} >
      <ScrollProgressInner style={{ width: `${width}%` }}></ScrollProgressInner>
    </ScrollProgressContainer>
  )
}

export default ScrollProgress

const ScrollProgressContainer = styled.div`
  background: #bcbdbd;
  border-radius: 0px 2px 0px 0px;
  height: 5px;
  position: ${props => props.fixed};
  top: ${props => props.fixed === 'fixed' && 0};
  width: 100%;
  z-index: 9;
`

const ScrollProgressInner = styled.span`
  background: linear-gradient(to right, #4ba492, #26686d);
  height: 5px;
  position: absolute;
`
