import { useStaticQuery, graphql } from 'gatsby'

export const useResourceTooltip = () => {
  const { allNodeResourceTooltip } = useStaticQuery(
    graphql`
      query resourceTooltip {
        allNodeResourceTooltip {
          nodes {
            field_heading_plain_text
            field_tooltip_description
          }
        }
      }
    `
  )
  return allNodeResourceTooltip.nodes
}
