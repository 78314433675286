import { getToken } from './auth';


const requestFavoriteResourceUrl = `${process.env.DRUPAL_JSONAPI_URL}favorite-resources`
const facetedResourcesUrl = `${process.env.DRUPAL_JSONAPI_URL}resources?_format=json`
const facetedResourcesByChapterUrl = `${process.env.DRUPAL_JSONAPI_URL}dashboard/resources?_format=json`

export const requestFavoriteResource = async (id, option) => {
  const token = getToken()

  const method = {
    get: 'GET',
    post: 'POST'
  }

  const response = await fetch(`${requestFavoriteResourceUrl}/${id}?_format=json`, {
    method: option === 'get' ? method.get : method.post,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }),
    body: option === 'post' ? JSON.stringify({}) : null
  });

  if (response.status === 200) return await response.json()
}


export const getFacetedResources = async body => {
  const response = await fetch(`${facetedResourcesUrl}`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(body)
  });

  if (response.status === 200) {
    return await response.json()
  }

  return []
}

export const getFacetedResourcesByChapter = async body => {
  const token = getToken()
  const response = await fetch(`${facetedResourcesByChapterUrl}`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }),
    body: JSON.stringify(body)
  });

  if (response.status === 200) {
    return await response.json()
  }

  return []
}

